import { useState } from "react";
import logo from "../../assets/logo.png";
import toast from "react-hot-toast";

const InputAmount = ({ getAmount }) => {
  const [amount, setAmount] = useState(0);
  const handleAmount = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9.]/g, "");
    e.target.value = onlyNums;
    if (value < 5 && value != 0) {
      toast.error("The amount can not be lower than 5 USD", {
        style: {
          borderRadius: "10px",
          background: "rgba(0, 0, 0, 0.507)",
          backdropFilter: "blur(10px)",
          color: "#ffffff",
          height: "25px",
          fontSize: "0.8rem",
          width: "100%",
          fontWeight: "bold",
        },
      });
    } else {
      setAmount(value);
    }
  };
  return (
    <>
      <div className='flex flex-col w-full justify-center'>
        <div class='bg-[#464646]  h-[40px]  w-[280px] m-auto text-white rounded-lg flex flex-row'>
          <input
            class='bg-transparent w-9/12 px-4 outline-none h-[40px] transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040]'
            name='text'
            placeholder='Enter amount'
            type='number'
            onChange={(e) => {
              handleAmount(e);
            }}
          />
          <h1 className='text-[#4eeb55] mt-1.5'>USD</h1>
          <img
            src={logo}
            alt=''
            className='w-[35px] h-[35px] object-contain mt-1'
          />
        </div>
        <button
          onClick={() => getAmount(amount)}
          className='bg-[#4eeb55] w-[50%] m-auto mt-6 font-bold text-[20px] rounded-md h-[40px]'
        >
          Next
        </button>
      </div>
    </>
  );
};

export default InputAmount;
