import usdt from "../../assets/usdt.svg";
import bnb from "../../assets/bnb.svg";
import { useState } from "react";

const ChosePayment = ({ coin, setCoin,next }) => {
  return (
    <>
      <div className='w-full mr-auto ml-auto flex flex-col'>
        <div className='w-full absolute top-2 text-center'>
          <h1 className='text-white font-bold text-[20px] m-auto '>
            Chose currency
          </h1>
        </div>
        <div
          className='bg-gray-600 w-[90%] rounded-md m-auto h-[50px] px-2 flex-row flex justify-between mt-10'
          style={{ border: `${coin === "usdt" ? "1px solid #4eeb55" : ""}` }}
          onClick={() => {
            setCoin("usdt");
          }}
        >
          <h1 className='text-[20px] font-semibold text-white mt-2'>
            <span>500</span> USDT on BSC
          </h1>
          <img
            src={usdt}
            alt=''
            className='w-[30px] h-[30px] object-cover mt-2.5'
          />
        </div>
        <div
          className='bg-gray-600 w-[90%] rounded-md m-auto h-[50px] px-2 mt-4 flex-row flex justify-between'
          style={{ border: `${coin === "bnb" ? "1px solid #4eeb55" : ""}` }}
          onClick={() => {
            setCoin("bnb");
          }}
        >
          <h1 className='text-[20px] font-semibold text-white mt-2'>
            <span>1</span> BNB on BSC
          </h1>
          <img
            src={bnb}
            alt=''
            className='w-[30px] h-[30px] object-cover mt-2.5'
          />
        </div>
        <button onClick={next} className='bg-[#4eeb55] w-[50%] m-auto mt-6 font-bold text-[20px] rounded-md h-[40px]'>
          Next
        </button>
      </div>
    </>
  );
};

export default ChosePayment;
