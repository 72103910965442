import { useState } from "react";

const LogAndReg = ({ login }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState();
  const [password, setPasswrod] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const checkComfirmPassword = () => {};

  return (
    <>
      <div className='flex flex-col w-full relative'>
        <input
          class='bg-[#464646] px-4 py-2 outline-none w-[280px] mr-auto ml-auto text-white rounded-lg border-2 transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040]'
          name='text'
          placeholder='Enter email '
          type='text'
        />
        <input
          class='bg-[#464646] mt-2 px-4 py-2 outline-none w-[280px] mr-auto ml-auto text-white rounded-lg border-2 transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040]'
          name='password'
          placeholder='Enter password'
          type='password'
        />
        {!isLogin && (
          <input
            class='bg-[#464646] mt-2 px-4 py-2 outline-none w-[280px] mr-auto ml-auto text-white rounded-lg border-2 transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040]'
            name='password'
            placeholder='Confirm password'
            type='password'
          />
        )}
        <button
          onClick={() => {
            if (isLogin) {
              login(email, password);
            }
          }}
          className='bg-[#4eeb55] w-[150px] h-[40px] text-black font-bold m-auto rounded-md mt-8'
        >
          {isLogin ? "Login" : "Sign up"}
        </button>

        {!isLogin ? (
          <h1 className='text-white m-auto mt-2 text-[14px]'>
            Alredy have an account ?{" "}
            <span
              className='text-[#4eeb55]'
              onClick={() => {
                setIsLogin(true);
              }}
            >
              Login
            </span>
          </h1>
        ) : (
          <h1 className='text-white m-auto mt-2 text-[14px]'>
            Don`t have an account ?{" "}
            <span
              className='text-[#4eeb55]'
              onClick={() => {
                setIsLogin(false);
              }}
            >
              Sign up
            </span>
          </h1>
        )}
      </div>
    </>
  );
};

export default LogAndReg;
