import { useEffect, useState } from "react";
import Qrcode from "react-qr-code";

const QrCodePage = () => {
  const [totalSeconds, setTotalSeconds] = useState(60 * 60);
  const [timeIsDone, setTimeIsDone] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(interval);
          setTimeIsDone(true);
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return (
    <>
      <div className='w-full m-auto justify-center flex flex-col'>
        <h1 className='text-white font-semibold m-auto'>
          Only send {150} <span className='font-bold text-[17px]'>USDT</span> on{" "}
          <span className='font-bold text-[17px]'>BSC</span> to this address
        </h1>
        <Qrcode
          value={"1"}
          className='w-[180x] h-[180x] m-auto mt-4'
          size={180}
        />
        <h1 className='text-white text-wrap text-[14px] m-auto mt-4'>
          0xb2d5405eAFAe28f5f35308e9bc99622e25DbD170
        </h1>
      </div>
      <div className='text-black absolute top-72 left-0 right-0 m-auto w-[200px] text-center bg-[#4eeb55] rounded-md'>
        <h1 className='text-[25px] font-semibold'>
          <span>{minutes}</span>:<span>{seconds}</span>
        </h1>
      </div>
    </>
  );
};

export default QrCodePage;
