import { useState } from "react";
import logo from "../../assets/logo.png";

const Final = ({ done }) => {
  return (
    <>
      <div className='w-full px-1 text-center'>
        {done ? (
          <>
            {" "}
            <img
              src={logo}
              alt=''
              className='w-[70px] h-[70px] m-auto object-cover'
            />
            <h1 className='text-white font-bold m-auto text-[25px]'>
              Congratulations, you have successfully bought 100{" "}
              <span className='text-[#4eeb55]'>PepeCat</span>
            </h1>
          </>
        ) : (
          <>
            <h1 className='text-red-500 font-bold m-auto text-[25px]'>
              Transactions Failed
            </h1>
            <h1 className='text-white font-semibold mt-2'>
              If you send any assets to the this address contact us
            </h1>
          </>
        )}
      </div>
    </>
  );
};

export default Final;
