import { useState } from "react";
import { MainPresale } from "../components";

const Presale = () => {
  return (
    <>
      <div className='w-[90%] m-auto flex flex-col mt-28 loadAnime'>
        <h1 className='text-white m-auto font-bold text-[35px]'>Presale</h1>
        <div className='w-[200px] h-[200px] rounded-full back-color-01 absolute m-auto z-[-1] left-0 right-0' />
        <div className='w-full h-[280px] bg-[#262926] rounded-md mt-4 flex flex-col justify-center relative'>
          <MainPresale />
        </div>
      </div>
    </>
  );
};

export default Presale;
