import { FaClipboardList } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa";
import { PiCoinsFill } from "react-icons/pi";
import { FaTelegram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

export const menuItems = [
  {
    text: "Presale",
    link: "/",
    icon: <PiCoinsFill className='m-auto text-[22px] mt-1' />,
  },
  {
    text: "Task",
    link: "/task",
    icon: <FaClipboardList className='m-auto text-[22px] mt-1' />,
  },
  {
    text: "Invaite",
    link: "/ref",
    icon: <FaUserPlus className='m-auto text-[22px] mt-1' />,
  },
];

export const taskItem = [
  {
    id: 1,
    text: "Subscribe telegram",
    reward: 500,
    icon: <FaTelegram className='text-[30px] m-auto mt-1' />,
    link: "https://t.me/pepecat_meme",
  },
  {
    id: 2,
    text: "Subscribe Group",
    reward: 500,
    icon: <FaTelegram className='text-[30px] m-auto mt-1' />,
    link: "https://t.me/pepecat_group",
  },
  {
    id: 3,
    text: "Follow X",
    reward: 500,
    icon: <BsTwitterX className='text-[30px] m-auto mt-1' />,
    link: "https://x.com/pepecat_meme",
  },
  {
    id: 4,
    text: "Repost our tweet",
    reward: 500,
    icon: <BsTwitterX className='text-[30px] m-auto mt-1' />,
    link: "https://x.com/pepecat_meme",
  },
];
