import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Menu, Header } from "./components";
import { Invaite, Presale, Task } from "./pages";
import toast, { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className='App'>
      <Toaster position='top-center' reverseOrder={false} />
      <Header />
      <Routes>
        <Route path='/' element={<Presale />} />
        <Route path='/task' element={<Task />} />
        <Route path='/ref' element={<Invaite />} />
      </Routes>
      <Menu />
    </div>
  );
}

export default App;

//scp -r build/* root@74.50.72.126:/var/www/pepecat