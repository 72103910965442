import { useState } from "react";
import InputAmount from "./InputAmount";
import ChosePayment from "./ChosePayment";
import QrCodePage from "./QrCode";
import Final from "./Final";
import LogAndReg from "./LogAndReg";

const MainPresale = () => {
  const [page, setPage] = useState("log");
  const [coin, setCoin] = useState("usdt");
  const [address, setAddress] = useState();
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(true);

  const register = (email, password) => {};
  const login = (email, password) => {
    setPage("Input-amount");
  };
  const getQrCode = () => {
    console.log(coin);
    setPage("Qrcode");
  };
  const getAmount = (amount) => {
    setPage("Chose-payment");
  };
  const calculateAmount = () => {};
  const checkTransaction = () => {};

  return (
    <>
      <div>
        {page === "log" && <LogAndReg register={register} login={login} />}
        {page === "Input-amount" && <InputAmount getAmount={getAmount} />}
        {page === "Chose-payment" && (
          <ChosePayment coin={coin} setCoin={setCoin} next={getQrCode} />
        )}
        {page === "Qrcode" && <QrCodePage />}
        {page === "Final" && <Final done={isTransactionSuccess} />}
      </div>
    </>
  );
};

export default MainPresale;
