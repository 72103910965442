import { FaUsers } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";

const Invaite = () => {
  return (
    <>
      <div className='w-[90%] m-auto flex flex-col mt-10 text-white loadAnime'>
        <div className='m-auto'>
          <div className='w-[300px] h-[250px] absolute left-0 right-0 m-auto back-color-01 z-[-1] rounded-full opacity-[0.6]' />
          <div className='z-10 mt-16 w-full text-center'>
            <h1 className='font-semibold text-[18px] m-auto text-gray-300'>
              Total invaite
            </h1>
            <div className='m-auto flex flex-row text-center w-[200px] '>
              <h1 className='font-bold text-[35px] ml-auto'>100</h1>
              <FaUsers className='text-[#4eeb55]  mr-auto ml-4 mt-3 text-[30px] ' />
            </div>
          </div>
        </div>
        <div className='w-full flex flex-row mt-8 justify-between'>
          <button className='bg-[#4eeb55] w-[80%] h-[50px] p-1 rounded-md font-bold text-[26px] text-black'>
            Invaite Friend
          </button>
          <button className='bg-[#4eeb55] w-[50px] h-[50px] rounded-md'>
            <FaCopy className='text-[35px] m-auto text-black' />
          </button>
        </div>
        <div className='w-full m-auto flex flex-col gap-2 h-full mt-10'>
          <div className='w-full rounded-md bg-[#262926] h-[60px]'></div>
          <div className='w-full rounded-md bg-[#262926] h-[60px]'></div>
        </div>
      </div>
    </>
  );
};

export default Invaite;
