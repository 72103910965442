import logo from "../assets/logo.png";

const Header = () => {
  return (
    <>
      <div className='fixed top-2 flex flex-row w-[90%] left-0 right-0 m-auto justify-between'>
        <h1 className='text-white font-semibold'>UserName</h1>
        <div className='flex flex-row text-white mt-1'>
          <h1 className='text-[14px] font-bold mr-2'>4.05k</h1>
          <img src={logo} alt='' className='w-[20px] h-[20px] object-cover' />
        </div>
      </div>
    </>
  );
};

export default Header;
