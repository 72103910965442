import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { taskItem } from "../constants";

const Task = () => {
  return (
    <>
      <div className='w-[90%] m-auto flex flex-col mt-14 text-white loadAnime'>
        <div className='w-[200px] h-[200px] rounded-full back-color-01 absolute m-auto z-[-1] left-0 right-0' />
        <img
          src={logo}
          alt=''
          className='w-[150px] h-[150px] object-cover m-auto'
        />
        <div className='flex flex-col w-full mt-8'>
          <h1 className='font-bold text-[25px]'>Task to do</h1>
          {taskItem.map((t) => (
            <Link to={t.link} target="_blank" className='bg-[#262926] w-full rounded-md h-[55px] mt-2 flex flex-row p-1 relative'>
              <div className='mt-auto mb-auto w-[40px] h-[40px] rounded-md bg-[#4eeb55] text-black text-center'>
                {t.icon}
              </div>
              <div className='flex flex-col mb-auto mt-auto ml-4'>
                <h1 className='font-bold'>{t.text}</h1>
                <div className='flex flex-row '>
                  <h1>+ {t.reward}</h1>
                  <img
                    src={logo}
                    alt=''
                    className='w-[18px] h-[18px] object-cover ml-1 mt-1'
                  />
                </div>
              </div>
              <button className='bg-[#4eeb55] rounded-md w-[80px] h-[30px] text-black absolute right-2 top-3.5 font-bold'>
                start
              </button>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Task;
